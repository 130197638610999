import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  Box,
  Typography,
} from "@mui/material";
import axios from "axios";
import io from "socket.io-client";
import Cookies from "js-cookie";
import FilterLeadClaims from "./leadClaims/FilterLeadClaims";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { styled } from "@mui/material";
import ShowStepper from "./ShowStepper";
import ViewDetailDialogueBox from "../app-component/ViewDetailDialogueBox";
import { useAuth } from "../contexts/AuthContext";
import DialogueBox from "./DialogueBox";

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1.1rem",
  minWidth: "180px",
  textTransform: "capitalize",
}));

const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const LeadClaimDashboard = () => {
  const [claimEvents, setClaimEvents] = useState([]);
  const [showFUBKEYDialog, setShowFUBKeyDialog] = useState(false);
  const { user } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showStepper, setShowStepper] = useState(false);
  const [open, setOpen] = useState(false); // Initially set to true to show the dialog

  const handleViewDetailsClick = () => {
    console.log("View details clicked");
    setOpen(true);
    // Show the stepper when button is clicked
  };

  const mergeAndSortEvents = useCallback((existingEvents, newEvents) => {
    console.log("Merging events:", { existingEvents, newEvents });
    const uniqueEventsMap = new Map();

    [...existingEvents, ...newEvents].forEach((event) => {
      const eventKey = `${event.leadId}-${event.claimedAt}`;
      if (!uniqueEventsMap.has(eventKey)) {
        uniqueEventsMap.set(eventKey, event);
      }
    });

    const sortedEvents = Array.from(uniqueEventsMap.values()).sort((a, b) => {
      return new Date(b.claimedAt) - new Date(a.claimedAt); // Newest first
    });

    console.log("Sorted events:", sortedEvents);
    return sortedEvents;
  }, []);

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);

    try {
      const API_BASE_URL =
        process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

      const response = await axios.get(
        `${API_BASE_URL}/api/claim-events?page=${currentPage}`
      );

      console.log(response.data, "response data------------");
      console.log("API response:", response.data);

      if (!response.data || !Array.isArray(response.data)) {
        throw new Error("Invalid data structure received from API");
      }

      setClaimEvents((prevEvents) =>
        mergeAndSortEvents(prevEvents, response.data)
      );
      // Assuming totalPages is managed elsewhere; if not, set it accordingly.
    } catch (error) {
      console.error(
        "Error fetching claim events:",
        error.response || error.message
      );
      setError(`Failed to fetch claim events: ${error.message}`);
    } finally {
      setLoading(false);
    }
  }, [currentPage, mergeAndSortEvents]);

  // const mergeAndSortEvents = useCallback((existingEvents, newEvents) => {
  //   console.log("Merging events:", { existingEvents, newEvents });
  //   const uniqueEventsMap = new Map();

  //   [...existingEvents, ...newEvents].forEach((event) => {
  //     event.history.forEach((historyItem) => {
  //       const eventKey = `${event.leadId}-${historyItem.eventTimestamp}-${historyItem.claimType}-${historyItem.claimedAt}`;
  //       if (
  //         !uniqueEventsMap.has(eventKey) ||
  //         new Date(historyItem.eventTimestamp) >
  //           new Date(uniqueEventsMap.get(eventKey).history[0].eventTimestamp)
  //       ) {
  //         uniqueEventsMap.set(eventKey, {
  //           ...event,
  //           history: [historyItem],
  //         });
  //       }
  //     });
  //   });

  //   const sortedEvents = Array.from(uniqueEventsMap.values()).sort((a, b) => {
  //     const aTimestamp = new Date(a.history[0].eventTimestamp).getTime();
  //     const bTimestamp = new Date(b.history[0].eventTimestamp).getTime();
  //     return bTimestamp - aTimestamp; // Newest first
  //   });

  //   console.log("Sorted events:", sortedEvents);
  //   return sortedEvents;
  // }, []);

  // const fetchData = useCallback(async () => {
  //   setLoading(true);
  //   setError(null);

  //   try {
  //     const API_BASE_URL =
  //       process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

  //     const response = await axios.get(
  //       `${API_BASE_URL}/api/claim-events?page=${currentPage}`
  //     );
  //     console.log(response.data, "response data------------");
  //     console.log(response.data.events, "response data------------");

  //     console.log(
  //       "Events:",
  //       response.data.events,
  //       "Type:",
  //       typeof response.data.events
  //     );

  //     console.log("API response:", response.data);

  //     if (!response.data || !Array.isArray(response.data.events)) {
  //       throw new Error("Invalid data structure received from API");
  //     }

  //     setClaimEvents((prevEvents) =>
  //       mergeAndSortEvents(prevEvents, response.data.events)
  //     );
  //     setTotalPages(response.data.totalPages);
  //   } catch (error) {
  //     // console.error("Error fetching claim events:", error);
  //     console.error(
  //       "Error fetching claim events:",
  //       error.response || error.message
  //     );

  //     setError(`Failed to fetch claim events: ${error.message}`);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [currentPage, mergeAndSortEvents]);

  // useEffect(() => {
  //   fetchData();

  //   const socket = io(
  //     process.env.REACT_APP_BACKEND_URL || "http://localhost:3004"
  //   );

  //   socket.on("connect", () => {
  //     console.log("WebSocket connected");
  //   });

  //   socket.on("leadClaimed", (newEvent) => {
  //     console.log("Received leadClaimed event:", newEvent);

  //     setClaimEvents((prevEvents) => {
  //       const updatedEvent = {
  //         leadId: newEvent.leadId,
  //         currentState: {
  //           leadName: newEvent.leadName,
  //           claimSource: newEvent.claimSource,
  //           leadStage: newEvent.leadStage,
  //         },
  //         history: [
  //           {
  //             claimTypeDetails: newEvent.claimTypeDetails,
  //             claimedAt: newEvent.claimedAt,
  //             eventTimestamp: newEvent.eventTimestamp,
  //             claimType: newEvent.claimType,
  //             timeToClaim: newEvent.timeToClaim,
  //           },
  //         ],
  //       };

  //       return mergeAndSortEvents(prevEvents, [updatedEvent]);
  //     });
  //   });

  //   socket.on("disconnect", () => {
  //     console.log("WebSocket disconnected");
  //   });

  //   return () => {
  //     console.log("Disconnecting WebSocket");
  //     socket.disconnect();
  //   };
  // }, [fetchData, mergeAndSortEvents]);

  useEffect(() => {
    console.log("Current claimEvents state:", claimEvents);
  }, [claimEvents]);
  useEffect(() => {
    if (user && user.subscriptionStatus === "active" && !user.fubApiKey) {
      setShowFUBKeyDialog(true);
      // return;
    }
  }, [user]);
  // Handle close button
  const handleClose = () => {
    setOpen(false);
  };

  const loadMore = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handleRefresh = () => {
    setCurrentPage(1);
    fetchData();
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      {showFUBKEYDialog ? (
        <DialogueBox />
      ) : (
        <div>
          {/* <Button onClick={handleRefresh} disabled={loading}>
            Refresh Data
          </Button> */}
          <FilterLeadClaims />
          {/* Agent performance metrics  */}

          <Box p={4}>
            <Typography variant="h5" component="h5" gutterBottom>
              Agent Performance Metrics
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>AGENT</TableCell>
                    <TableCell>FTC CLAIMS</TableCell>
                    <TableCell>POND CLAIMS</TableCell>
                    <TableCell>REASSIGNED TO</TableCell>
                    <TableCell>REASSIGNED FROM</TableCell>
                    <TableCell>RELEASE TO POND</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                    <TableCell colSpan={8}>No claim events found</TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell>Bob</TableCell>
                    <TableCell>12</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>3</TableCell>
                  </TableRow>

                  {/* 2nd row */}
                  <TableRow>
                    <TableCell>Bob</TableCell>
                    <TableCell>12</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>3</TableCell>
                  </TableRow>

                  {/* 3rd row  */}
                  <TableRow>
                    <TableCell>Bob</TableCell>
                    <TableCell>12</TableCell>
                    <TableCell>8</TableCell>
                    <TableCell>4</TableCell>
                    <TableCell>2</TableCell>
                    <TableCell>3</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Lead Claims Overview  */}
          <Box p={4}>
            <Typography variant="h5" component="h5" gutterBottom>
              Lead Claims Overview
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>LEAD ID</TableCell>
                    <TableCell>LEAD NAME</TableCell>
                    <TableCell>AGENT</TableCell>
                    <TableCell>CLAIM TYPE</TableCell>
                    <TableCell>POND</TableCell>
                    <TableCell>CLAIM DATE</TableCell>
                    <TableCell>ACTION</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <TableRow>
                    <TableCell colSpan={8}>No claim events found</TableCell>
                  </TableRow> */}

                  <TableRow>
                    <TableCell>James Smith</TableCell>
                    <TableCell>12345</TableCell>
                    <TableCell>Bob</TableCell>
                    <TableCell>First to Claim</TableCell>
                    <TableCell>Seller Pond</TableCell>
                    <TableCell>2024-09-15</TableCell>
                    <TableCell>
                      <StyledButton
                        // onClick={handleSaveSelections}
                        variant="contained"
                        // color="secondary"
                        size="small"
                        onClick={handleViewDetailsClick}
                      >
                        View Details
                      </StyledButton>
                      {/* {<ViewDetailDialogueBox />} */}
                      <Dialog
                        open={open} // Correctly use the open state here
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle id="responsive-dialog-title">
                          Lead Details
                        </DialogTitle>
                        <DialogContent sx={{ height: "200px", width: "500px" }}>
                          <ShowStepper />
                        </DialogContent>

                        <DialogActions></DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>

                  {/* 2nd row */}

                  <TableRow>
                    <TableCell>James Smith</TableCell>
                    <TableCell>12345</TableCell>
                    <TableCell>Bob</TableCell>
                    <TableCell>First to Claim</TableCell>
                    <TableCell>Seller Pond</TableCell>
                    <TableCell>2024-09-15</TableCell>
                    <TableCell>
                      <StyledButton
                        variant="contained"
                        size="small"
                        // onClick={() => handleViewDetailsClick(historyItem._id)}
                        onClick={handleViewDetailsClick}
                      >
                        View Details
                      </StyledButton>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle id="responsive-dialog-title">
                          Lead Details
                        </DialogTitle>
                        <DialogContent sx={{ height: "200px", width: "500px" }}>
                          <ShowStepper
                          // events={claimEvents}
                          // claimId={selectedClaimId}
                          />
                        </DialogContent>
                        <DialogActions></DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>

                  {/* 3rd row */}

                  <TableRow>
                    <TableCell>James Smith</TableCell>
                    <TableCell>12345</TableCell>
                    <TableCell>Bob</TableCell>
                    <TableCell>First to Claim</TableCell>
                    <TableCell>Seller Pond</TableCell>
                    <TableCell>2024-09-15</TableCell>
                    <TableCell>
                      <StyledButton
                        // onClick={handleSaveSelections}
                        variant="contained"
                        // color="secondary"
                        size="small"
                      >
                        View Details
                      </StyledButton>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="responsive-dialog-title"
                      >
                        <DialogTitle id="responsive-dialog-title">
                          Lead Details
                        </DialogTitle>
                        <DialogContent sx={{ height: "200px", width: "500px" }}>
                          <ShowStepper
                          // events={claimEvents}
                          // claimId={selectedClaimId}
                          />
                        </DialogContent>
                        <DialogActions></DialogActions>
                      </Dialog>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {loading && <CircularProgress />}
          {currentPage < totalPages && (
            <Button onClick={loadMore} disabled={loading}>
              Load More
            </Button>
          )}
        </div>
      )}
    </>

    // ------------------------------------
    // <div>
    //   <Button onClick={handleRefresh} disabled={loading}>
    //     Refresh Data
    //   </Button>
    //   <TableContainer component={Paper}>
    //     <Table>
    //       <TableHead>
    //         <TableRow>
    //           <TableCell>LEAD NAME</TableCell>
    //           <TableCell>CLAIM DETAILS</TableCell>
    //           <TableCell>CLAIMED AT</TableCell>
    //           <TableCell>EVENT TIMESTAMP</TableCell>
    //           <TableCell>SOURCE</TableCell>
    //           <TableCell>LEAD STAGE</TableCell>
    //           <TableCell>CLAIM TYPE</TableCell>
    //           <TableCell>TIME TO CLAIM (MS)</TableCell>
    //         </TableRow>
    //       </TableHead>
    //       <TableBody>
    //         {claimEvents.length === 0 ? (
    //           <TableRow>
    //             <TableCell colSpan={8}>No claim events found</TableCell>
    //           </TableRow>
    //         ) : (
    //           claimEvents.flatMap((event, eventIndex) =>
    //             event.history.map((historyItem, historyIndex) => {
    //               const uniqueKey = `${event.leadId}-${historyItem.eventTimestamp}-${eventIndex}-${historyIndex}`;
    //               return (
    //                 <TableRow key={uniqueKey}>
    //                   <TableCell>
    //                     {event.currentState?.leadName || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.claimTypeDetails?.description ||
    //                       "No details"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.claimedAt
    //                       ? new Date(historyItem.claimedAt).toLocaleString()
    //                       : "N/A"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.eventTimestamp
    //                       ? new Date(
    //                           historyItem.eventTimestamp
    //                         ).toLocaleString()
    //                       : "N/A"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {event.currentState?.claimSource || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {event.currentState?.leadStage || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>
    //                     {historyItem.claimType || "Unknown"}
    //                   </TableCell>
    //                   <TableCell>{historyItem.timeToClaim || 0}</TableCell>
    //                 </TableRow>
    //               );
    //             })
    //           )
    //         )}
    //       </TableBody>
    //     </Table>
    //   </TableContainer>
    //   {loading && <CircularProgress />}
    //   {currentPage < totalPages && (
    //     <Button onClick={loadMore} disabled={loading}>
    //       Load More
    //     </Button>
    //   )}
    // </div>
  );
};

export default LeadClaimDashboard;
//  hello world
