import { Widgets } from "@mui/icons-material";
import {
  Autocomplete,
  styled,
  Typography,
  Grid,
  TextField,
  Container,
} from "@mui/material";
import React, { useState } from "react";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
    Width: "250px",
  },
}));
const SelectAgentDropDown = ({ agents }) => {
  const [selectedAgent, setSelectedAgent] = useState("");

  return (
    <Grid item xs={2}>
      <Typography variant="h6" component="h2" gutterBottom>
        Agents
      </Typography>
      <StyledAutocomplete
        options={agents}
        value={selectedAgent}
        onChange={(event, value) => setSelectedAgent(value)}
        renderInput={(params) => (
          <TextField {...params} label="Select agents" variant="outlined" />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Grid>
  );
};

export default SelectAgentDropDown;
