import React, { useState } from "react";
import { styled, Typography, Grid, TextField, Button } from "@mui/material";

const StyledButton = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: "1.1rem",
  minWidth: "180px",
  marginTop: "2.2rem",
}));
const FilterButton = () => {
  return (
    <Grid item xs={2}>
      <StyledButton
        // onClick={handleSaveSelections}
        variant="contained"
      >
        Apply Filter
      </StyledButton>
    </Grid>
  );
};

export default FilterButton;
