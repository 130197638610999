import { Grid, TextField, Box, Typography } from "@mui/material";
import React from "react";
import DatePicker from "react-datepicker";

const SelectDate = () => {
  return (
    <Grid item sx={4}>
      <Box display="flex" gap={2}>
        <Box>
          <Typography variant="h6" component="h2" gutterBottom>
            Start Date
          </Typography>
          <DatePicker
            // selected={startDate}
            // onChange={(date) => setStartDate(date)}
            selectsStart
            // startDate={startDate}
            // endDate={endDate}
            isClearable
            customInput={<TextField label="Start Date" variant="outlined" />}
            popperClassName="calendar-popper"
            popperPlacement="top"
          />
        </Box>
        <Box>
          <Typography variant="h6" component="h2" gutterBottom>
            End date
          </Typography>
          <DatePicker
            // selected={endDate}
            // onChange={(date) =>
            //   setEndDate(
            //     date ? new Date(date.setHours(23, 59, 59, 999)) : null
            //   )
            // }
            selectsEnd
            // startDate={startDate}
            // endDate={endDate}
            // minDate={startDate}
            isClearable
            customInput={<TextField label="End Date" variant="outlined" />}
            popperClassName="calendar-popper"
            popperPlacement="top"
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default SelectDate;
