import React from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
} from "@mui/material";

const steps = [
  {
    label: "Released to buyer by Lee Adkins",

    // (
    //   <Box gap={2}>
    //     <Typography>Released to buyer by Lee Adkins</Typography>
    //     <Typography>2:29 am</Typography>
    //   </Box>
    // ),
  },
  {
    label: "Assigned to Lee Adkins  by Lee Adkins",
    // description: `4:30 am `,
  },
  {
    label: "Claimed By from buyer Lee adkins and assigned to Joey",
    // description: `8:30 pm `,
  },
];

const ShowStepper = () => {
  // const [activeStep, setActiveStep] = React.useState(0);

  return (
    <Box>
      <Stepper orientation="vertical">
        {steps.map((step, index) => (
          <Step key={step.label} disabled>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              {/* <Typography>{step.description}</Typography> */}
              {/* Remove button elements here */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

export default ShowStepper;
