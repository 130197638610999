import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Box,
  CircularProgress,
  Container,
  Paper,
  TextField,
  Divider,
  Card,
  CardContent,
  Grid,
  Alert,
} from "@mui/material";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import Loader from "../app-component/Loader";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const CheckoutForm = ({ onSubscriptionComplete, promoCode }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/api/billing/create-subscription", {
        promoCode,
      });
      const { clientSecret } = response.data;

      if (clientSecret) {
        const result = await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardElement),
          },
        });

        if (result.error) {
          setError(result.error.message);
        } else if (result.paymentIntent.status === "succeeded") {
          onSubscriptionComplete();
        }
      } else {
        onSubscriptionComplete();
      }
    } catch (err) {
      console.error("Subscription creation error:", err);
      setError("Failed to create subscription. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe || loading}
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "Subscribe"}
      </Button>
    </form>
  );
};

const UpdatePaymentMethodForm = ({ onPaymentMethodUpdated }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchSetupIntent = async () => {
      try {
        const response = await api.post("/api/billing/create-setup-intent");
        const { clientSecret } = response.data;
        // Use the clientSecret here or store it in state
      } catch (err) {
        console.error("Error fetching setup intent:", err);
        setError("Failed to initialize payment method update");
      }
    };
    fetchSetupIntent();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    try {
      const response = await api.post("/api/billing/update-payment-method");
      const { clientSecret } = response.data;

      const result = await stripe.confirmCardSetup(clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });

      if (result.error) {
        setError(result.error.message);
      } else {
        onPaymentMethodUpdated();
      }
    } catch (err) {
      console.error("Payment method update error:", err);
      setError("Failed to update payment method. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      <Button
        type="submit"
        variant="contained"
        color="primary"
        disabled={!stripe || loading}
        sx={{ mt: 2 }}
      >
        {loading ? <CircularProgress size={24} /> : "Update Payment Method"}
      </Button>
    </form>
  );
};

const SubscriptionManagement = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [message, setMessage] = useState("");
  const { user, updateUser, logout, refreshUser } = useAuth();

  const fetchSubscription = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await api.get("/api/billing/subscription");
      console.log("Subscription API response:", response.data);

      setSubscription(response.data);
      updateUser({ ...user, subscriptionStatus: response.data.status });
    } catch (error) {
      console.error("Error fetching subscription:", error);
      if (error.response && error.response.status === 401) {
        setError("Your session has expired. Please log in again.");
        logout();
      } else {
        setError("An error occurred while fetching the subscription.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    refreshUser();
    fetchSubscription();
  }, [refreshUser]);

  const handlePaymentMethodUpdated = () => {
    fetchSubscription();
    setMessage("Payment method updated successfully");
  };

  const handleCancelSubscription = async () => {
    try {
      await api.post("/api/billing/cancel-subscription");
      await fetchSubscription();
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setError("Failed to cancel subscription. Please try again.");
    }
  };

  const handleReactivateSubscription = async () => {
    try {
      await api.post("/api/billing/reactivate-subscription");
      await fetchSubscription();
    } catch (error) {
      console.error("Error reactivating subscription:", error);
      setError("Failed to reactivate subscription. Please try again.");
    }
  };

  const handleApplyPromoCode = async () => {
    setLoading(true);
    setError(null);
    try {
      await api.post("/api/billing/apply-promo", { promoCode });
      setMessage("Promo code applied successfully!");
      await fetchSubscription();
    } catch (error) {
      console.error("Failed to apply promo code:", error);
      setError(
        error.response?.data?.error ||
          "Failed to apply promo code. Please try again."
      );
    } finally {
      setLoading(false);
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const getDiscountDetails = (subscription) => {
    if (!subscription.discountPercent && !subscription.discountAmount) {
      return "No discount applied";
    }

    if (subscription.discountPercent) {
      return `${subscription.discountPercent}% off`;
    } else if (subscription.discountAmount) {
      return `${formatCurrency(
        subscription.discountAmount,
        subscription.currency
      )} off`;
    }

    return "Discount applied";
  };

  const getNextBillingAmount = (subscription) => {
    if (!subscription || typeof subscription.amount !== "number") {
      console.error("Invalid subscription data:", subscription);
      return "0.00";
    }

    const amount = subscription.amount;
    const discountPercent = subscription.discountPercent || 0;
    const discountAmount = subscription.discountAmount || 0;

    let finalAmount = amount;

    if (discountPercent === 100) {
      finalAmount = 0;
    } else if (discountPercent > 0) {
      finalAmount = amount * (1 - discountPercent / 100);
    } else if (discountAmount > 0) {
      finalAmount = amount - discountAmount;
    }

    finalAmount = finalAmount > 0 ? finalAmount : 0;

    return finalAmount.toFixed(2);
  };

  const formatCurrency = (amount, currency) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(amount);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Card variant="outlined" sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom color="primary">
            Your Subscription
          </Typography>
          {subscription ? (
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Status:</strong> {subscription.status.toUpperCase()}
                  {subscription.cancelAtPeriodEnd && " (Cancels at period end)"}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Current Period End:</strong>{" "}
                  {formatDate(subscription.currentPeriodEnd)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Applied Discount:</strong>{" "}
                  {getDiscountDetails(subscription)}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Next bill:</strong>{" "}
                  {formatCurrency(
                    getNextBillingAmount(subscription),
                    subscription.currency
                  )}{" "}
                  on {formatDate(subscription.currentPeriodEnd)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {subscription.cancelAtPeriodEnd ? (
                  <Typography variant="body1" color="error">
                    Your subscription will end on{" "}
                    {formatDate(subscription.currentPeriodEnd)}
                  </Typography>
                ) : (
                  <Button
                    onClick={handleCancelSubscription}
                    variant="outlined"
                    color="secondary"
                    sx={{ mt: 2 }}
                  >
                    Cancel Subscription
                  </Button>
                )}
                {subscription.cancelAtPeriodEnd && (
                  <Button
                    onClick={handleReactivateSubscription}
                    variant="outlined"
                    color="primary"
                    sx={{ mt: 2, ml: 2 }}
                  >
                    Reactivate Subscription
                  </Button>
                )}
              </Grid>
            </Grid>
          ) : (
            <>
              <Typography>No active subscription found.</Typography>
              <Elements stripe={stripePromise}>
                <CheckoutForm
                  onSubscriptionComplete={fetchSubscription}
                  promoCode={promoCode}
                />
              </Elements>
            </>
          )}
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ mb: 4 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom color="primary">
            Update Payment Method
          </Typography>
          <Elements stripe={stripePromise}>
            <UpdatePaymentMethodForm
              onPaymentMethodUpdated={handlePaymentMethodUpdated}
            />
          </Elements>
        </CardContent>
      </Card>

      <Card variant="outlined">
        <CardContent>
          <Typography variant="h6" gutterBottom color="primary">
            Apply Promo Code
          </Typography>
          <Box>
            <TextField
              fullWidth
              label="Enter Promo Code"
              value={promoCode}
              onChange={(e) => setPromoCode(e.target.value)}
              margin="normal"
            />
            <Button
              onClick={handleApplyPromoCode}
              variant="contained"
              color="primary"
              sx={{ mt: 2 }}
            >
              Apply Promo Code
            </Button>
          </Box>
        </CardContent>
      </Card>

      {error && (
        <Typography color="error" sx={{ mt: 2 }}>
          {error}
        </Typography>
      )}
      {message && (
        <Typography color="success" sx={{ mt: 2 }}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

const Settings = () => {
  const [fubApiKey, setFubApiKey] = useState("");
  const [message, setMessage] = useState("");
  const { user, updateFubApiKey, logout } = useAuth();
  const [isSaving, setIsSaving] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user && user.fubApiKey) {
      setFubApiKey(user.fubApiKey);
    }
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);

    try {
      await updateFubApiKey(fubApiKey);
      setMessage("Follow Up Boss API key updated successfully");
    } catch (error) {
      console.error("Error updating FUB API key:", error);
      setMessage("Error updating Follow Up Boss API key");
    } finally {
      setIsSaving(false);
    }
  };

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ mt: 4, p: 4 }}>
        <Typography variant="h4" gutterBottom>
          Settings
        </Typography>
        {user && user.subscriptionStatus !== "active" && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            Your subscription is not active. Please update your subscription to
            access all features.
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            label="Follow Up Boss API Key"
            value={fubApiKey}
            onChange={(e) => setFubApiKey(e.target.value)}
            margin="normal"
            type="password"
          />
          <Button
            disabled={isSaving}
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {isSaving ? <CircularProgress size={24} /> : "Save API Key"}
          </Button>
        </Box>
        {message && (
          <Typography
            color={message.includes("Error") ? "error" : "success"}
            sx={{ mt: 2 }}
          >
            {message}
          </Typography>
        )}
        <Divider sx={{ my: 4 }} />
        <Typography variant="h5" gutterBottom>
          Subscription Management
        </Typography>
        <SubscriptionManagement />
        <Divider sx={{ my: 4 }} />
        <Button onClick={handleLogout} variant="contained" color="secondary">
          Logout
        </Button>
      </Paper>
    </Container>
  );
};

export default Settings;
