// import React, { useCallback } from "react";
import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import SelectAgentDropDown from "./SelectAgentDropDown";
import SelectPondDropDown from "./SelectPondDropDown";
import { Box, Container, Grid, styled } from "@mui/material";
import { Diversity2TwoTone } from "@mui/icons-material";
import SelectClaimTypeDropDown from "./SelectClaimTypeDropDown";
import FilterButton from "./FilterButton";
import SelectDate from "./SelectDate";
const API_BASE_URL =
  process.env.REACT_APP_API_BASE_URL || "http://localhost:3004";

const StyledContainer = styled(Container)(({ theme }) => ({
  maxWidth: "1400px !important",
  padding: theme.spacing(4),
}));
const FilterLeadClaims = () => {
  const [agents, setAgents] = useState([]);
  const [ponds, setPonds] = useState([]);
  const [claimType, setClaimType] = useState("");
  const [selectedAgent, setSelectedAgent] = useState("");
  const [selectedPond, setSelectedPond] = useState("");
  const [selectedClaimType, setSelectedClaimType] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState("");

  // fetch agents
  const fetchAgents = useCallback(async () => {
    try {
      // const token = localStorage.getItem("token");
      const token = Cookies.get("token");
      const response = await axios.get(`${API_BASE_URL}/api/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const sortedAgents = Object.entries(response.data).sort(([, a], [, b]) =>
        a.localeCompare(b)
      );
      return sortedAgents.map(([id, name]) => ({ value: id, label: name }));
    } catch (error) {
      console.error("Error fetching agents:", error);
      setErrorMessage("Error fetching agents. Please try again.");
      return [];
    }
  }, []);

  // fetch ponds ================================================
  const fetchPonds = useCallback(async () => {
    try {
      // const token = localStorage.getItem("token");
      const token = localStorage.getItem("token");
      const response = await axios.get(`${API_BASE_URL}/api/ponds`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log(response);
      if (!Array.isArray(response.data)) {
        throw new Error("Response data is not an array");
      }

      // Sort the ponds by name
      const sortedPonds = response.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      console.log(sortedPonds, "sortedPonds");

      // Map to the desired format
      return sortedPonds.map(({ id, name }) => ({ value: id, label: name }));
    } catch (error) {
      console.error("Error fetching ponds:", error);
      setErrorMessage("Error fetching ponds. Please try again.");
      return [];
    }
  }, []);

  // Fetch data ==================================================
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const [agentOptions, pondOptions] = await Promise.all([
        fetchAgents(),
        fetchPonds(),
      ]);

      setAgents(agentOptions);
      setPonds(pondOptions);
      //   setClaimType(stageOptions);

      //   await fetchSelections(agentOptions, smartListOptions, stageOptions);
    } catch (error) {
      console.error("Error fetching data:", error);
      setErrorMessage("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  }, [fetchAgents, fetchPonds]);
  console.log(ponds, "ponds");

  // calling fetchData function in useEffect()

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Box p={4}>
      <Grid container spacing={1}>
        <SelectAgentDropDown agents={agents} />
        <SelectPondDropDown ponds={ponds} />
        <SelectClaimTypeDropDown ponds={ponds} />
        {/* <SelectPondDropDown ponds={ponds} /> */}
        <SelectDate />
        <FilterButton />
      </Grid>
    </Box>
  );
};

export default FilterLeadClaims;
