import React, { useState } from "react";
import {
  Autocomplete,
  styled,
  Typography,
  Grid,
  TextField,
} from "@mui/material";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  "& .MuiInputBase-root": {
    fontSize: "1.1rem",
    padding: theme.spacing(1),
    // width: "150px",
  },
}));

const SelectPondDropDown = ({ ponds }) => {
  const [selectedPond, setSelectedPond] = useState("");

  return (
    <Grid item xs={2} md={2}>
      <Typography variant="h6" component="h2" gutterBottom>
        Ponds
      </Typography>
      <StyledAutocomplete
        options={ponds}
        value={selectedPond}
        onChange={(event, value) => setSelectedPond(value)}
        renderInput={(params) => (
          <TextField {...params} label="Select ponds" variant="outlined" />
        )}
        isOptionEqualToValue={(option, value) => option.value === value.value}
      />
    </Grid>
  );
};

export default SelectPondDropDown;
